import { Box, SimpleGrid } from '@chakra-ui/react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { HeroBackground } from 'common/components/molecules/HeroBackground'
import React from 'react'
import { Icon } from './Icon'
import { PillarLinkCard, PillarLinkCardProps } from './PillarLinkCard'
export interface HeroWithPillarLinksProps {}

export const HeroWithPillarLinks: React.FC<HeroWithPillarLinksProps> = (
    props
) => {
    const { cards } = getHeroData(props)
    return (
        <div>
            <HeroBackground>
                <Box
                    maxW='1126px'
                    mx='auto'
                    pt='48px'
                    pb='24px'
                    display='flex'
                    flexDirection={['column', 'column', 'column', 'row']}
                    gap='24px'
                    px={['0', '0', '24px']}
                >
                    <Box
                        maxW='455px'
                        display='flex'
                        flexDirection={['column']}
                        px={['32px', '32px', '0']}
                        justifyContent='center'
                    >
                        <Box display={['none', 'none', 'block']} mb='12px'>
                            <Icon />
                        </Box>
                        <Text
                            variant={['title-md', 'title-lg', 'title-2xl']}
                            color={['white', 'white', 'white']}
                        >
                            <strong>All you need</strong> to get covered
                        </Text>
                        <Text variant='subtitle-md' color='white' mt='12px'>
                            Get started or use one of our quote calculators to
                            help plan ahead.
                        </Text>
                    </Box>
                    <SimpleGrid
                        columns={2}
                        spacing='8px'
                        w='full'
                        px={['16px', '16px', '0']}
                    >
                        {cards.map((card) => (
                            <PillarLinkCard key={card.variant} {...card} />
                        ))}
                    </SimpleGrid>
                </Box>
            </HeroBackground>
        </div>
    )
}

const getHeroData = (props: any) => {
    return {
        cards: [
            {
                variant: 'life',
                cta: {
                    text: props.life_insurance_label,
                    href: props.life_insurance_url,
                    analyticsId: props.life_insurance_analytics_id,
                },
            },
            {
                variant: 'health',
                cta: {
                    text: props.health_label,
                    href: props.health_url,
                    analyticsId: props.health_analytics_id,
                },
            },
            {
                variant: 'pensions',
                cta: {
                    text: props.pensions_label,
                    href: props.pensions_url,
                    analyticsId: props.pensions_analytics_id,
                },
            },
            {
                variant: 'investments',
                cta: {
                    text: props.investments_label,
                    href: props.investments_url,
                    analyticsId: props.investments_analytics_id,
                },
            },
        ] as PillarLinkCardProps[],
    }
}
