import { Box, Image } from '@chakra-ui/react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { BasicButton } from 'common/components/atoms/BasicButton'
import { HeroBackground } from 'common/components/molecules/HeroBackground'
import React from 'react'
import { HeroWithPillarLinks } from '../HeroWithPillarLinks'
import { JumbotronProps } from '../Jumbotron'

export interface HeroProps extends JumbotronProps {
    type?: string
}

const mobileClipPath = 'polygon(0 0, 100% 0, 100% 100%, 0% 66%)'
const desktopClipPath = 'polygon(12% 0, 100% 0, 100% 100%, 0% 100%)'

export const Hero: React.FC<HeroProps> = (props) => {
    if (props.type === 'templatesection___herowithpillarlinks')
        return <HeroWithPillarLinks {...props} />

    const { image, callToAction } = props
    return (
        <>
            <HeroBackground>
                <Box
                    display='flex'
                    flexDirection={['column', 'column', 'column', 'row', 'row']}
                    mx='auto'
                >
                    <Box
                        display='grid'
                        gap='24px'
                        gridTemplateColumns={[
                            '1fr',
                            '1fr',
                            '1fr',
                            'auto 472px 632px auto',
                        ]}
                        w='full'
                    >
                        <Box display={['none', 'none', 'none', 'block']} />
                        <Box order={[2, 2, 2, 1]}>
                            <Box
                                mx={['32px', '32px', '32px', '0']}
                                pb='8px'
                                mt={[0, 0, 0, '55px']}
                            >
                                <Box
                                    display={['none', 'none', 'none', 'block']}
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='38'
                                        height='38'
                                        viewBox='0 0 38 38'
                                        fill='none'
                                    >
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M6.89583 28.6208H5.71667C3.11172 28.6208 1 26.5091 1 23.9042V19.1875C1 16.5825 3.11172 14.4708 5.71667 14.4708H6.89583C7.54707 14.4708 8.075 14.9988 8.075 15.65V27.4417C8.075 28.0929 7.54707 28.6208 6.89583 28.6208Z'
                                            stroke='#49C5DD'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M31.6581 28.6208H30.479C29.8277 28.6208 29.2998 28.0929 29.2998 27.4417V15.65C29.2998 14.9988 29.8277 14.4708 30.479 14.4708H31.6581C34.2631 14.4708 36.3748 16.5825 36.3748 19.1875V23.9042C36.3748 26.5091 34.2631 28.6208 31.6581 28.6208Z'
                                            stroke='#49C5DD'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                        <path
                                            d='M5.7168 14.4708C5.7168 7.30724 11.524 1.5 18.6876 1.5V1.5C22.1277 1.5 25.4269 2.86657 27.8594 5.29907C30.2919 7.73157 31.6585 11.0308 31.6585 14.4708'
                                            stroke='#49C5DD'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                        <path
                                            d='M23.4043 34.5167H26.9418C29.5467 34.5167 31.6585 32.405 31.6585 29.8V29.8V28.6208'
                                            stroke='#49C5DD'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M21.0458 36.875H18.6874C17.385 36.875 16.3291 35.8191 16.3291 34.5167V34.5167C16.3291 33.2142 17.385 32.1583 18.6874 32.1583H21.0458C22.3482 32.1583 23.4041 33.2142 23.4041 34.5167V34.5167C23.4041 35.8191 22.3482 36.875 21.0458 36.875Z'
                                            stroke='#49C5DD'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                        <path
                                            d='M13.9707 24.5992C16.6435 27.0349 20.7312 27.0349 23.404 24.5992'
                                            stroke='#49C5DD'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                        <path
                                            d='M13.9707 16.0193V18.0821'
                                            stroke='#49C5DD'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                        <path
                                            d='M23.4043 16.0193V18.0821'
                                            stroke='#49C5DD'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                    </svg>
                                </Box>

                                <Text
                                    variant={[
                                        'title-md',
                                        'title-md',
                                        'title-md',
                                        'title-xl',
                                    ]}
                                    color={['white', 'white', 'white', 'white']}
                                    my={['12px', '12px', '12px', '24px']}
                                >
                                    <strong>Financial advice</strong> you can
                                    trust
                                </Text>
                                <Text
                                    variant='subtitle-md'
                                    color='white'
                                    mb='32px'
                                >
                                    {props.subtitle}
                                </Text>
                                <BasicButton
                                    {...callToAction!}
                                    label={callToAction?.label}
                                    variant='bright'
                                />
                            </Box>
                        </Box>
                        <Image
                            gridColumn='span 2'
                            order={[1, 1, 1, 2]}
                            width='100%'
                            sx={{
                                aspectRatio: '16/9',
                            }}
                            src={image}
                            alt='Hero'
                            srcSet={`
                        ${image}?auto=format&w=150 150w,
                        ${image}?auto=format&w=300 300w,
                        ${image}?auto=format&w=600 600w,
                        ${image}?auto=format&w=1200 1200w,
                        ${image}?auto=format&w=2400 2400w,
                        `}
                            sizes='(max-width: 767px) 100vw, 50vw'
                            objectFit='cover'
                            // height={['100%', '100%', '100%', '450px']}
                            minH='350px'
                            maxHeight='450px'
                            clipPath={[
                                mobileClipPath,
                                mobileClipPath,
                                mobileClipPath,
                                desktopClipPath,
                            ]}
                        />
                    </Box>
                </Box>
            </HeroBackground>
        </>
    )
}
