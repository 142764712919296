import { Box } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

export interface HeroBackgroundProps extends PropsWithChildren<{}> {}

const mobileSvg = (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='100%'
        height='100%'
        viewBox='0 0 375 1430'
        fill='none'
    >
        <path
            d='M0 0H375V1377.13L0 1430L0 0Z'
            fill='url(#paint0_linear_4456_39006)'
        />
        <defs>
            <linearGradient
                id='paint0_linear_4456_39006'
                x1='16.9891'
                y1='141.167'
                x2='206.566'
                y2='148.211'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#333791' />
                <stop offset='1' stopColor='#5A60AA' />
            </linearGradient>
        </defs>
    </svg>
)

const desktopSvg = (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='100%'
        height='100%'
        viewBox='0 0 1440 900'
        fill='none'
    >
        <path
            d='M0 0H1440V695.5L0 899.652V0Z'
            fill='url(#paint0_linear_4456_39005)'
        />
        <defs>
            <linearGradient
                id='paint0_linear_4456_39005'
                x1='65.2381'
                y1='88.8118'
                x2='758.559'
                y2='246.051'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#333791' />
                <stop offset='1' stopColor='#5A60AA' />
            </linearGradient>
        </defs>
    </svg>
)

export const HeroBackground: React.FC<HeroBackgroundProps> = ({ children }) => {
    return (
        <>
            <Box position='relative' mb='26px'>
                <Box
                    position='absolute'
                    w='full'
                    zIndex='-1'
                    display={['none', 'none', 'none', 'block']}
                >
                    {desktopSvg}
                </Box>
                <Box
                    position='absolute'
                    w='full'
                    zIndex='-1'
                    display={['block', 'block', 'block', 'none']}
                >
                    {mobileSvg}
                </Box>
                {children}
            </Box>
        </>
    )
}
