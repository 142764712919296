import React from 'react'

export interface IconProps {}

export const Icon: React.FC<IconProps> = () => {
    return (
        <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M31.677 16.3985V7.49193C31.677 5.01047 29.6678 3 27.1879 3H10.4891C8.00919 3 6 5.01047 6 7.49193V31.7371C6 34.2185 8.00919 36.229 10.4891 36.229H15.7369'
                stroke='#49C5DD'
                stroke-width='1.6'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M10.3018 11.5971H27.5837'
                stroke='#49C5DD'
                stroke-width='1.6'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M10.3018 17.1365H27.5837'
                stroke='#49C5DD'
                stroke-width='1.6'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M10.3018 22.8943H21.0503'
                stroke='#49C5DD'
                stroke-width='1.6'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M21.3838 36.2293L22.9363 30.6899L29.9896 23.6322C31.1347 22.4864 32.9964 22.4864 34.1415 23.6322C35.2866 24.778 35.2866 26.6409 34.1415 27.7867L26.8072 35.1186L21.3838 36.2293Z'
                stroke='#49C5DD'
                stroke-width='1.6'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M29.0225 24.5944L33.0619 28.6364'
                stroke='#49C5DD'
                stroke-width='1.6'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
        </svg>
    )
}
