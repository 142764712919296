import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { ArrowRightCircleIcon } from '@irishlife/ilgroupdesignsystem.icons'
import { PillarIcon } from '@irishlifedigitalhub/ds.atoms.pillar-icon'
import { navigate } from '@reach/router'
import { fireAnalyticsEvent } from 'common/utils/fireAnalyticsEvent'
import React from 'react'

export interface PillarLinkCardProps {
    variant: 'life' | 'health' | 'pensions' | 'investments'
    cta: {
        text: string
        href: string
        analyticsId: string
    }
}

export const variants = {
    life: {
        text: 'Life Insurance',
    },
    health: {
        text: 'Health Insurance',
    },
    pensions: {
        text: 'Pensions',
    },
    investments: {
        text: 'Investments',
    },
}

export const PillarLinkCard: React.FC<PillarLinkCardProps> = ({
    variant,
    cta,
}) => {
    return (
        <Card
            borderTop={variant}
            display='flex'
            flexDirection='column'
            alignItems='center'
        >
            <PillarIcon filled variant={variant} size='xsm' />
            <Text variant='title-xs' color='black' pt='8px' pb='24px'>
                {variants[variant].text}
            </Text>
            <Button
                w='174px'
                rightIcon={<ArrowRightCircleIcon w='28px' h='28px' />}
                onClick={() => {
                    fireAnalyticsEvent({
                        name: 'secondaryCtaClick',
                        properties: {
                            clickText: cta.text,
                            clickURL: cta.href,
                            clickID: cta.analyticsId,
                        },
                    })
                    navigate(cta.href)
                }}
            >
                {cta.text}
            </Button>
        </Card>
    )
}
